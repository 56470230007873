<template>
  <vs-popup title="DESGLOSE DEL PAGO" :active.sync="isPaymentsPopupActive">
    <div>
      <div class="p-6" >
        <h6>Descripción:</h6>
        <p class="mb-6">{{data.concept}}</p>

        <h6>Estado:</h6>
        <p class="mb-6">{{statusStr(data.status)}}</p>

        <h6>Fecha de Pago:</h6>
        <p class="mb-6">{{paymentDate()}}</p>

        <div v-if="data.request != null" >
          <h6 class="mb-3">Desglose de la operación:</h6>
          <table aria-describedby="concepts">
            <thead>
              <tr>
                <th scope="col" class="text-left bold">CONCEPTO</th>
                <th scope="col" class="text-right bold">MONTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left pb-3 pr-3" style="font-size:13px">{{data.request.concept}}</td>
                <td class="text-right pb-3" style="font-size:13px" >{{costFormat(data.request.amount)}}</td>
              </tr>
              <tr v-for="c in commissions" :key="c.id">
                <td class="text-left pb-3 pr-3" style="font-size:13px">{{c.concept}}</td>
                <td class="text-right pb-3" style="font-size:13px" >-{{costFormat(c.amount)}}</td>
              </tr>
              <tr>
                <td colspan="2"> <hr style="width: 100%"> </td>
              </tr>
              <tr>
                <td class="text-right pb-3 bold pr-4" style="font-size:15px">TOTAL: </td>
                <td class="text-right pb-3" style="font-size:13px" >{{costFormat(data.amount)}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </vs-popup>
</template>

<script>
import formatHelper from '../../mixins/formatHelper';
import dateHelper from '../../mixins/dateHelper';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [formatHelper, dateHelper],
  data () {
    return {
    }
  },
  computed: {
     isPaymentsPopupActive: {
      get() {
        return this.$store.state.isPaymentsPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_PAYMENTS_POPUP_ACTIVE", val);
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    commissions() {
      if(this.data != null && this.data.request != null 
        && this.data.request.commissions != null ){

          return this.data.request.commissions.filter(f => f.status > 0)
      }
      else{
        return [];
      }
    }
  },
  methods: {
    statusStr(value){
      if(value == 0){
        return "Creado"
      }
      else if(value == 1){
        return "En Proceso"
      }
      else if (value == 2){
        return "Pagado"        
      }
      else {
        return "-"
      }
    },
    paymentDate(){
      if(this.data.status == "0" || this.data.status == "1" || this.data.trx_confirmation_date == "0000-00-00 00:00:00"){
        return "No se ha realizado el pago";
      }
      else {
        return this.dateTimeFormat(this.data.trx_confirmation_date);
      }
    },
    hideSideBar(){
      this.$store.commit("TOGGLE_IS_PAYMENTS_POPUP_ACTIVE", false);
    }
  }
}
</script>
