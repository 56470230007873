<template>
  <div id="proveedor">

    <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
              <h1 class="rg-big-title extrabold rg-title">Mis<span class="orange"> pagos</span>.</h1>
          </div>
        </div>
    </vx-card>

    <vx-card>
      <template v-if="payments > 0">
        <div class="vx-row">
          <div class="vx-col w-full md:w-2/3" >
            <p class="bold mb-4">
              Aquí podrás ver la lista de los pagos de tus proyectos. Al dar click sobre un registro, se mostrará el detalle y desglose de la operación.
            </p>
          </div> 
        </div>
      </template>
      <template v-else >
        <div v-if="loaded">
          <h2>No tienes pagos registrados</h2>
          <p>Podrás regresar cuando lo desees, para administrar los registros que existan.</p>
        </div>
      </template>

      <payments-list @loadedPayments="loadedPayments" ></payments-list>
    </vx-card>
  </div>
</template>

<script>
import PaymentsList from '../../components/supplier/projects/PaymentsList.vue';
export default {
  components: {
    PaymentsList
  },
  data() {
    return {
      payments: 0,
      loaded: false,
    }
  },
  methods: {
    loadedPayments(count) {
      this.payments = count;
      this.loaded = true;
    },
  }
}
</script>