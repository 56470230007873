<template>

  <div class="" id="main-div" > 
    <payment-detail-popup :data="sidebarData" />

    <vs-table ref="table" stripe pagination v-model="paymentSelected" max-items="10" @selected="handleSelected" :data="payments" noDataText="No hay datos disponibles" style="min-height: 28rem !important;">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4" >
        <div class="flex flex-wrap-reverse items-center data-list-btn-container mr-4">      
          <vx-input-group class="mb-4 mr-6">
            <template slot="prepend">
              <div class="prepend-text bg-gray" style="padding: 0.5rem">
                <feather-icon style="width:18px" icon="CalendarIcon" />
              </div>
            </template>
            <datepicker style="max-width:200px"        
            placeholder="Fecha inicio"
            v-model="date.start"
            format="dd/MM/yyyy"
            :language="es"
            :use-utc="true" />       
          </vx-input-group>

          <vx-input-group class="mb-4">
            <template slot="prepend">
              <div class="prepend-text bg-gray" style="padding: 0.5rem">
                <feather-icon style="width:18px" icon="CalendarIcon" />
              </div>
            </template>
            <datepicker style="max-width:200px"        
            placeholder="Fecha fin"
            v-model="date.end"
            format="dd/MM/yyyy"
            :language="es"
            :use-utc="true" />       

          </vx-input-group>
        </div>

        <vx-input-group class="mb-4">
          <vs-input style="max-width:280px; width:auto"
            v-model="filters.q"
            v-on:keyup.enter="onEnter"
            placeholder="PID, Alias de proyecto" />
          <template slot="append">
            <div class="append-text btn-addon">      
              <vs-button class="search-btn" color="primary" @click="loadPayments">
                <feather-icon style="width:18px" icon="SearchIcon" />
              </vs-button>
              <vs-button class="search-btn" color="dark" @click="clearSearch">
                <feather-icon style="width:18px" icon="DeleteIcon" />
              </vs-button>
            </div>
          </template>
        </vx-input-group>       

      </div>

      <template slot="thead">
        <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
          <vs-td>
            {{ tr.project_id }}
          </vs-td>
          <vs-td>
            {{ tr.alias }}
          </vs-td>
          <vs-td>
            {{ tr.concept }}
          </vs-td>
          <vs-td>
            {{ costFormat(tr.amount) }}
          </vs-td>
          <vs-td>
            {{ statusStr(tr.status) }}
          </vs-td>

          <vs-td>
            {{ dateStr(tr) }}
          </vs-td>

           <vs-td :data="tr.id">
            <vs-button v-if="tr.rg_invoice_id !== null" size="small" type="border" class="mb-2" target :href="$sanitizeUrl(`${ApiDomain}/storage/private/rg-invoice/${tr.project_id}/${tr.pdf_file}?access_token=${accessToken}`)" >PDF</vs-button>
            <vs-button v-if="tr.rg_invoice_id !== null" size="small" type="border" class="" target :href="$sanitizeUrl(`${ApiDomain}/storage/private/rg-invoice/${tr.project_id}/${tr.xml_file}?access_token=${accessToken}`)" >XML</vs-button>
          </vs-td>
          
        </vs-tr>
      </template>
    </vs-table>

  </div>
</template>

<script>
import formatHelper from '../../mixins/formatHelper';
import dateHelper from '../../mixins/dateHelper';
import PaymentDetailPopup from '../projects/PaymentDetailPopup';
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import {mapState} from "vuex";

const columnDefinitions = [
  { title: "PID", key: "project_id", sortable: true },
  { title: "ALIAS", key: "alias", sortable: true },
  { title: "DESCRIPCIÓN", key: "concept", sortable: true },
  { title: "TOTAL", key: "amount", sortable: true },
  { title: "ESTADO", key: "status", sortable: true },
  { title: "FECHA DE PAGO", key: "status", sortable: true },
  { title: "FACTURA", key: "invoice", sortable: false }
];

const itemsPerPageDefinitions = [5,10,25,50];

export default {
  name: "payments-list",
  props: [],
  mixins: [formatHelper, dateHelper],
  components: {
    PaymentDetailPopup,
    Datepicker
  },
  mounted() {
    this.supplier_id = this.SupplierId;
    this.loadPayments();
  },
  data() {
    return {
      payments: [],
      allPayments : [],
      orderKey: "",
      projectIdToDelete: -1,
      columns: columnDefinitions,
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[0],
      isMounted: false,
      loading: false,
      paymentSelected: null,
      // Data Sidebar
      showSideBar: false,
      sidebarData: {},
      es: es,

      supplier_id: null,

      filters:{
        q: "",
      },

      date: {
        start: "",
        end: ""
      }
    };
  },
  computed: {
    ...mapState("auth", ["access_token"]),
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.payments.length
    },
    accessToken(){
      return this.access_token;
    },
  },
  watch: {
    loading: function(val){
      if(val) {
        this.$vs.loading({
          container: '#main-div',
          scale: 1
        })
      }
      else {
        this.$vs.loading.close('#main-div > .con-vs-loading')
      }
    },
  },
  methods: {
    async loadPayments(){
      this.isMounted = false;
      this.loading = true;
      let payload = {filters: this.filters}

      try {
        let s = null;
        if(this.date.start){        
          s = this.setDateToStartOfDay(this.date.start);
        }

        let e = null;
        if(this.date.end){
          e = this.setDateToEndOfDay(this.date.end);          
        }

        payload.filters.date = { start: s, end: e};
        let response = await axios.post(`/api/v1/supplier/${this.supplier_id}/withdrawals`, payload);
        this.allPayments = response.data;
        // no mostrar los que estan en estatus creado
        this.payments =  this.allPayments.filter(p => p.status > 0);
        this.$emit("loadedPayments", this.payments.length);              
      } catch (error) {}
      this.isMounted = true;
      this.loading = false;    
    },
    async clearSearch(){
      this.filters.q = "";
      this.date.start = null;
      this.date.end = null;
      await this.loadPayments();
    },
    async onEnter(){
      await this.loadPayments();
    },
    valOrPending(value){
      return value == 0 ? 'Pendiente' : value;
    },
    statusStr(value){
      if(value == 0){
        return "Creado"
      }
      else if(value == 1){
        return "En Proceso"
      }
      else if (value == 2){
        return "Pagado"        
      }
      else {
        return "-"
      }
    },
    dateStr(payment){
      if(payment.status == "0" || payment.status == "1" || payment.trx_confirmation_date == "0000-00-00 00:00:00"){
        return "--";
      }
      else {
        return this.dateTimeFormat(payment.trx_confirmation_date);
      }
    },
    handleSelected(p){
      this.sidebarData = p;
      this.$store.commit('TOGGLE_IS_PAYMENTS_POPUP_ACTIVE', true);    
    },
     toggleDataSidebar (val = false) {
      this.showSideBar = val
    }
  },
};
</script>


<style lang="css">
  .bg-gray {
    background-color: gray;
  }
  .vx-input-group .append-text.btn-addon .vs-button.search-btn {
    padding: 0 1rem !important;
  }
</style>
